<template>
  <div class="data-contain">
    <div class="card-wrapper">
      <div class="monitor-search">
        <div class="monitor-date">
          <Date-picker format="yyyy-MM-dd" type="daterange" placeholder="选择日期" @on-change="handleChangeDate"></Date-picker>
        </div>
        <div class="tab-contain">
          <router-link class="static-button" :to="{name: 'Body'}" >统计显示</router-link>
          <router-link :to="{name: 'BodyTable'}">数据显示</router-link>
          <router-link class="chart-button" :to="{name: 'BodyChart'}">图表显示</router-link>
        </div>
      </div>
    </div>
    <div class="chart" ref="body"></div>
  </div>
</template>
<script type="text/ecmascript-6">
import echarts from 'echarts';
import MemberService from '@/services/memberService';
export default {
	data() {
		return {
			bodyData: {
				index: 2,
				start: '',
				end: '',
				page: 1,
				size: 10,
			},
			timeData: [],
			weightList: [],
			musculi_skeletiList: [],
			bmcList: [],
			body_fatList: [],
			buildList: [],
			body_waterList: [],
			basicList: [],
			lactoneList: [],
			Charts: '',
		};
	},
	computed: {
		memberId() {
			return this.$route.params.member_id;
		},
	},
	methods: {
		handleInit() {
			this.bodyData.member_id = this.memberId;
			this.timeData = [];
			this.weightList = [];
			this.musculi_skeletiList = [];
			this.bmcList = [];
			this.body_waterList = [];
			this.body_fatList = [];
			this.buildList = [];
			this.basicList = [];
			this.lactoneList = [];
			MemberService.bodyQuery(this.bodyData).then((data) => {
				data.data_show.forEach((item) => {
					this.timeData.unshift(item.create_time);
					this.weightList.unshift(item.weight);
					this.musculi_skeletiList.unshift(item.musculi_skeleti);
					this.bmcList.unshift(item.bmc);
					this.body_waterList.unshift(item.body_water);
					this.body_fatList.unshift(item.body_fat);
					this.buildList.unshift(item.build);
					this.basicList.unshift(item.basic);
					this.lactoneList.unshift(item.lactone);
				});
				this.Charts.hideLoading();
				this.Charts.setOption({
					xAxis: {
						data: this.timeData,
					},
					series: [
						{
							// 根据名字对应到相应的系列
							name: '体重',
							data: this.weightList,
						},
						{
							name: '体格指数',
							data: this.buildList,
						},
						{
							name: '体脂肪率',
							data: this.body_fatList,
						},
						{
							name: '内脂指数',
							data: this.lactoneList,
						},
						{
							name: '体水分率',
							data: this.body_waterList,
						},
						{
							name: '骨骼肌率',
							data: this.musculi_skeletiList,
						},
						{
							name: '骨矿含量',
							data: this.bmcList,
						},
						{
							name: '基础代谢',
							data: this.basicList,
						},
					],
				});
			});
		},
		handleChangeDate(value) {
			this.bodyData.start = value[0];
			this.bodyData.end = value[1];
			this.handleInit();
		},
	},
	mounted() {
		this.handleInit();
		// echarts实例化
		this.Charts = echarts.init(this.$refs.body);
		// 初始化实例
		var options = {
			title: {
				text: '整体趋势',
				textStyle: {
					color: '#424e67',
					fontSize: '16',
				},
				padding: 25,
			},
			backgroundColor: '#f1f2f7',
			grid: {
				bottom: 80,
			},
			color: [
				'#32d2c9',
				'#f8a20f',
				'#5886e6',
				'#ff6c60',
				'#a9d96c',
				'#ff2525',
				'#1e1c2f',
				'#8c5e2a',
			],
			legend: {
				data: [
					'体重',
					'体格指数',
					'体脂肪率',
					'内脂指数',
					'体水分率',
					'骨骼肌率',
					'骨矿含量',
					'基础代谢',
				],
				x: 'right',
				orient: 'vertical',
				padding: 25,
				textStyle: {
					color: '#666',
					fontSize: 12,
				},
			},
			xAxis: {
				type: 'category',
				boundaryGap: false,
				axisLine: { onZero: false },
				data: [],
			},
			yAxis: [
				{
					// name: 'cm',
					type: 'value',
				},
			],
			tooltip: {
				trigger: 'axis',
				formatter: function (params) {
					return (
						params[0].name +
						'</br>' +
						params[0].marker +
						'体重：' +
						params[0].value +
						'kg' +
						'</br>' +
						params[1].marker +
						'体格指数：' +
						params[1].value +
						'</br>' +
						params[2].marker +
						'体脂肪率：' +
						params[2].value +
						'%' +
						'</br>' +
						params[3].marker +
						'内脂指数：' +
						params[3].value +
						'</br>' +
						params[4].marker +
						'体水分率：' +
						params[4].value +
						'%' +
						'</br>' +
						params[5].marker +
						'骨骼肌率：' +
						params[5].value +
						'%' +
						'</br>' +
						params[6].marker +
						'骨矿含量：' +
						params[6].value +
						'%' +
						'</br>' +
						params[7].marker +
						'基础代谢：' +
						params[7].value +
						'kcal' +
						'</br>'
					);
				},
				axisPointer: {
					type: 'cross',
					animation: false,
					label: {
						backgroundColor: '#505765',
					},
				},
			},
			dataZoom: [
				{
					show: true,
				},
				{
					type: 'inside',
				},
			],
			series: [
				{
					name: '体重',
					type: 'line',
					symbolSize: 10,
					animation: false,
					areaStyle: {
						normal: {
							color: 'transparent',
						},
					},
					lineStyle: {
						normal: {
							width: 3,
							color: '#32d2c9',
						},
					},
					itemStyle: {
						normal: {
							lineStyle: {
								color: '#fff',
							},
						},
					},
					data: [],
				},
				{
					name: '体格指数',
					type: 'line',
					symbolSize: 10,
					animation: false,
					areaStyle: {
						normal: {
							color: 'transparent',
						},
					},
					lineStyle: {
						normal: {
							width: 3,
							color: '#f8a20f',
						},
					},
					itemStyle: {
						normal: {
							lineStyle: {
								color: '#fff',
							},
						},
					},
					data: [],
				},
				{
					name: '体脂肪率',
					type: 'line',
					symbolSize: 10,
					animation: false,
					areaStyle: {
						normal: {
							color: 'transparent',
						},
					},
					lineStyle: {
						normal: {
							width: 3,
							color: '#5886e6',
						},
					},
					itemStyle: {
						normal: {
							lineStyle: {
								color: '#fff',
							},
						},
					},
					data: [],
				},
				{
					name: '内脂指数',
					type: 'line',
					symbolSize: 10,
					animation: false,
					areaStyle: {
						normal: {
							color: 'transparent',
						},
					},
					lineStyle: {
						normal: {
							width: 3,
							color: '#ff6c60',
						},
					},
					itemStyle: {
						normal: {
							lineStyle: {
								color: '#fff',
							},
						},
					},
					data: [],
				},
				{
					name: '体水分率',
					type: 'line',
					symbolSize: 10,
					animation: false,
					areaStyle: {
						normal: {
							color: 'transparent',
						},
					},
					lineStyle: {
						normal: {
							width: 3,
							color: '#a9d96c',
						},
					},
					itemStyle: {
						normal: {
							lineStyle: {
								color: '#fff',
							},
						},
					},
					data: [],
				},
				{
					name: '骨骼肌率',
					type: 'line',
					symbolSize: 10,
					animation: false,
					areaStyle: {
						normal: {
							color: 'transparent',
						},
					},
					lineStyle: {
						normal: {
							width: 3,
							color: '#ff2525',
						},
					},
					itemStyle: {
						normal: {
							lineStyle: {
								color: '#fff',
							},
						},
					},
					data: [],
				},
				{
					name: '骨矿含量',
					type: 'line',
					symbolSize: 10,
					animation: false,
					areaStyle: {
						normal: {
							color: 'transparent',
						},
					},
					lineStyle: {
						normal: {
							width: 3,
							color: '#1e1c2f',
						},
					},
					itemStyle: {
						normal: {
							lineStyle: {
								color: '#fff',
							},
						},
					},
					data: [],
				},
				{
					name: '基础代谢',
					type: 'line',
					symbolSize: 10,
					animation: false,
					areaStyle: {
						normal: {
							color: 'transparent',
						},
					},
					lineStyle: {
						normal: {
							width: 3,
							color: '#8c5e2a',
						},
					},
					itemStyle: {
						normal: {
							lineStyle: {
								color: '#fff',
							},
						},
					},
					data: [],
				},
			],
		};
		this.Charts.setOption(options);
		this.Charts.showLoading();
	},
};
</script>
<style lang="css" scoped>
</style>
